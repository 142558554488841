import React, { useEffect } from 'react'

import helper from '../services/helper'
import { Layout } from '../layouts/Layout'
import { HeroBanner, Showcase, SEO } from '../components/shared'
import { AddressButton } from '../components/nbn/'
import { PlanSuggestion } from '../components/shared/plan-suggestion'

const ProductNbn = () => {
  useEffect(() => {
    if (window.innerWidth > 991) {
      helper.syncHeights('.showcase-item h3')
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={'Pronto Broadband NBN'}
        description="Connect your world with Broadband that never sleeps!"
      />
      <main id="page-product-nbn">
        <HeroBanner />

        <AddressButton />

        <Showcase />

        <PlanSuggestion pageType="nbn" />
      </main>
    </Layout>
  )
}

export default ProductNbn
